import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components';

import Card from './Card';

const EnhancedCard = styled(Card)<{ size: string }>`
  width: 315px;
  margin-top: 32px;
  box-shadow: none;

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 900px;
    flex-direction: ${(props) => (props.size === 'big' ? 'column' : 'row-reverse')};
  }
`;

const Image = styled.img<{ size: string }>`
  height: 182px;
  width: 264px;
  align-self: center;
  border-radius: 12px;
  object-fit: cover;

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: ${(props) => (props.size === 'big' ? '853px' : '347px')};
    height: ${(props) => (props.size === 'big' ? '406px' : '240px')};
  }
`;

const Title = styled.h1`
  margin-top: 8px;
  font-size: 28px;
  line-height: 36px;
  color: ${(props) => props.theme.colors.blackText};
  text-align: left;
  font-weight: 600;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 32px;
    line-height: 42px;
  }
`;

const ImageSection = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
`;

const TextSection = styled.div<{ size: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-right: ${(props) => props.size === 'small' && '30px'};
  }
`;

const Paragraph = styled.p<{ size: string }>`
  margin-top: 14px;
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
  color: ${(props) => props.theme.colors.olive};
  text-align: justify;
  overflow: hidden;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 24px;
    line-height: 36px;
  }
`;

const Link = styled.a``;

const CardD: FC<{
  imageUrl: string;
  title: string;
  paragraph: string;
  size?: string;
  path?: string;
}> = ({ imageUrl, paragraph, title, size, path }) => {
  const theme = useTheme();
  const trimText = size === 'big' ? paragraph.substring(0, 170) : paragraph.substring(0, 190);
  const introText = trimText.substring(0, Math.min(trimText.length, trimText.lastIndexOf(' '))); // to get last word fully

  return (
    <Link href={path}>
      <EnhancedCard
        style={{ backgroundColor: `${theme.colors.lightPeach}`, cursor: 'pointer' }}
        size={size}
      >
        <ImageSection>
          <Image src={imageUrl} size={size} />
        </ImageSection>
        <TextSection size={size}>
          <Title>{title}</Title>
          <Paragraph size={size}>
            {introText}...<i style={{ textDecoration: 'underline' }}>read more</i>
          </Paragraph>
        </TextSection>
      </EnhancedCard>
    </Link>
  );
};

export default CardD;
