import React, { useState, useEffect, FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';

import { theme } from '../styles/theme';

import CardD from '../components/cards/CardD';
import Wave from '../components/Wave';
import MetaTags from '../components/MetaTags';
import Header from '../components/Header2';

import data from '../sections/marketing/data';
import { graphql } from 'gatsby';
import FooterNew from '../sections/common/FooterWithoutFormFactorHookSecondary';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.lightPeach2};
  scroll-behavior: smooth;
  padding: 0 20px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${(props) => props.theme.containerWidthForBreakpoints.laptop};
  margin-right: auto;
  margin-left: auto;
  margin-top: 60px;
  justify-content: center;
  align-content: center;
  align-items: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin-top: 100px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin-bottom: 100px;
    margin-top: 100px;
  }
`;

const BlogHeading = styled.div`
  width: 100%;
  max-width: 400px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    max-width: 1025px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    max-width: 1025px;
  }

  h1 {
    color: ${(props) => props.theme.colors.blackText};
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    margin: 0 0 16px 0;

    @media ${(props) => props.theme.breakpoints.tabletP} {
      font-size: 44px;
      line-height: 55px;
      margin: 0 0 16px 0;
    }
    @media ${(props) => props.theme.breakpoints.laptop} {
      margin-top: 12px;
      font-size: 64px;
      line-height: 70px;
    }

    span {
      color: ${(props) => props.theme.colors.orange};
    }
  }

  p {
    margin-top: 14px;
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
    color: ${(props) => props.theme.colors.olive};
    text-align: justify;
    overflow: hidden;

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: 24px;
      line-height: 36px;
    }
  }
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 100px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: column;
    align-items: stretch;
  }
`;

const CardEnhanced = styled(CardD)`
  margin-top: 32px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin-top: 32px;
  }
`;

const WaveEnhanced = styled(Wave)`
  bottom: 0;
  margin-bottom: -2%;
  width: 100%;
`;

type Post = {
  frontmatter: { title: string; slug: string; ogImage: string };
  excerpt: string;
};

const Posts: FC<{ data: { allMarkdownRemark: { edges: [] } } }> = ({
  data: { allMarkdownRemark },
}) => {
  const [scrolled, setScrolled] = useState(false);
  const posts = allMarkdownRemark.edges.map(({ node }) => node);
  console.log({ posts, allMarkdownRemark });
  console.log("posts")
  console.log(posts)

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener('scroll', changeBackground);
  });

  function getIntroText(htmlText: string) {
    return htmlText.replace(/<[^>]+>/g, '');
  }

  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='Blog | illume'
        description={
          'illume makes it easy to celebrate others. We offer ecards, group cards, group gifts and more for any occasion. Let’s make someone’s day, together.'
        }
      />
      <Layout>
        <Header />
        <Container>
          <BlogHeading>
            <h1>
              <span>illume</span> Blog
            </h1>
            <p>Your go-to source for advice on gratitude, gifting and group celebrations.</p>
          </BlogHeading>
          <CardsContainer>
            {posts.map((post: Post, el) => {
              const introText = getIntroText(post.excerpt);
              return (
                <CardEnhanced
                  imageUrl={post.frontmatter.ogImage}
                  title={post.frontmatter.title}
                  paragraph={introText}
                  path={post.frontmatter.slug}
                  size={!el ? 'big' : 'small'}
                />
              );
            })}
          </CardsContainer>
        </Container>
      </Layout>
      <FooterNew isBlog={true} />
    </ThemeProvider>
  );
};

// modified from https://www.gatsbyjs.com/docs/adding-a-list-of-markdown-blog-posts/#creating-the-graphql-query
// without the `date` field
export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250, format: HTML)
          frontmatter {
            date
            slug
            title
            ogImage
          }
        }
      }
    }
  }
`;

export default Posts;
