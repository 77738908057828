import React, { FC, useState, useEffect } from 'react';

import orangeWave from '../icons/orange-wave.svg';
import lightPeachWave from '../icons/light-peach-wave.svg';
import lightPeach2Wave from '../icons/light-peach-2-wave.svg';
import darkWave from '../icons/dark-wave.svg';

const Wave: FC<{
  color?: 'orange' | 'lightPeach' | 'lightPeach2' | 'dark';
  className?: string;
}> = ({ className, color = 'orange' }) => {
  const [src, setSrc] = useState<string>('');

  useEffect(() => {
    switch (color) {
      case 'orange': {
        setSrc(orangeWave);
        break;
      }
      case 'lightPeach': {
        setSrc(lightPeachWave);
        break;
      }
      case 'lightPeach2': {
        setSrc(lightPeach2Wave);
        break;
      }
      case 'dark': {
        setSrc(darkWave);
        break;
      }
      default: {
        break;
      }
    }
  }, [color]);

  return <img className={className} src={src} alt='' />;
};

export default Wave;
